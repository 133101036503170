import type { Member } from "../members";
import type { ProtocolTypes } from "../types";

// Enums

export enum SessionTypes {
  DIAGNOSTIC_ASSESSMENT = "*OCD DIAGNOSTIC ASSESSMENT",
  THERAPY_SESSION = "Therapy Session",
  FIRST_THERAPY_SESSION = "First Therapy Session",
  MID_THERAPY_SESSION = "Mid Therapy Session",
  THERAPY_CHECK_IN = "Therapy Check In",
  LOC_THERAPY_CHECK_IN = "LOC THERAPY CHECK-IN",
  SUPPORT_CHECK_IN = "Support Check In",
  THREE_MONTH_SUPPORT_CHECK_IN = "3 Month Support Check In",
  SIX_MONTH_SUPPORT_CHECK_IN = "6 Month Support Check In",
  NINE_MONTH_SUPPORT_CHECK_IN = "9 Month Support Check In",
  TWELVE_MONTH_SUPPORT_CHECK_IN = "12 Month Support Check In",
  FORMS_PLACEHOLDER = "Forms Placeholder",
}

export enum SessionTypesV2 {
  INITIAL_ASSESSMENT = "Initial Assessment",
  FOLLOWUP_ASSESSMENT = "Followup Assessment",
  THERAPY_SESSION = "Therapy Session",
  THERAPY_SESSION_WITH_FORMS = "Therapy Session w/ Forms",
  RESTART_DIAGNOSTIC_ASSESSMENT = "Reassessment",
  FORMS_PLACEHOLDER = "Forms Placeholder",
}

export enum SessionTypesV3 {
  FIRST_SESSION = "First Session",
  SECOND_SESSION = "Second Session",
  THERAPY_SESSION = "Therapy Session",
  THERAPY_SESSION_WITH_FORMS = "Therapy Session w/ Forms",
  REASSESSMENT = "Reassessment",
  FORMS_PLACEHOLDER = "Forms Placeholder",
  NONMEMBER_EDUCATION_SESSION = "Non-member Education Session",
}

export type AllSessionTypes = SessionTypes | SessionTypesV2 | SessionTypesV3;

export enum AppointmentActions {
  NEW_APPOINTMENT = "New Appointment",
  RESCHEDULE_APPOINTMENT = "Reschedule Appointment",
  CHANGE_APPOINTMENT_TYPE = "Change Appointment",
}

export enum AppointmentStatuses {
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  SCHEDULED = "scheduled",
  PENDING = "pending",
}

export enum SupervisorAppointmentNoteReviewStatus {
  PENDING_CHANGES = "pending changes",
  PENDING_REVIEW = "pending review",
  APPROVED = "approved",
}

export interface Appointment {
  "premium_users.protocol_type": ProtocolTypes;
  active_subscription: number;
  admd_visit_id: string;
  amd_appt_backfill: string;
  appointment_date: string;
  appointment_state: AppointmentStatuses;
  appointment_type: string;
  appt_created_at: string;
  autoinvoice_id: string;
  bg_address_setter: string;
  bg_diagnostics_id: string;
  bg_followup_changes_to_doctor_visits: string;
  bg_followup_changes_to_doctor_visits_text: string;
  bg_followup_changes_to_medication: string;
  bg_followup_changes_to_medication_text: string;
  bg_reported_current_address: string;
  billing_complete: string;
  billing_state: string;
  booked_author: string;
  cancellation_author: string;
  cancellation_info: string;
  cancellation_reason: string;
  cancellation_reason_detail: string;
  cancellation_ts: string;
  cancelled_via_reschedule: string;
  cancelled_waive_late_fee: number;
  cancelled_waive_late_fee_reason: string;
  cancelled_waive_late_fee_reason_notes: string;
  cancelled_within_24_hours: number;
  clinical_forms_dass21_id: string;
  clinical_forms_diamond_adult_id: string;
  clinical_forms_diamond_youth_id: string;
  clinical_forms_docs_id: string;
  clinical_forms_qles_id: string;
  clinician_compensated: string;
  clinician_email: string;
  clinician_signed_email: string;
  clinician_timezone: string;
  clinician_image_id: string | null;
  completion_at: string;
  completion_info: string;
  created_at: string;
  dass_anxiety: string;
  dass_depression: string;
  dass_stress: string;
  diagnosed_with_ocd: string;
  diagnostic_timezone: string;
  diamond_distress_score: string;
  diamond_functional_score: string;
  diamond_severity_score: string;
  discharge: string;
  docs_severity: string;
  end_time_in_iso8601_utc: string;
  end_ts: string;
  event_id: string;
  first_name: string;
  gcal_id: string;
  has_forms: boolean;
  has_scores: string;
  id: number;
  is_test_user: string;
  last_name: string;
  medical_additional_comments: string;
  medical_json: string;
  medical_within_normal_limits: string | number;
  member_takeaway_notes: string;
  member_user_id: string;
  needs_audit: string;
  needs_billing_review: string;
  needs_billing_review_notes: string;
  needs_clinical_review: string;
  needs_clinical_review_notes: string;
  needs_review_notes: string;
  needs_service_team_to_reschedule: number;
  needs_service_team_to_reschedule_status: string;
  needs_supervisor_review: number;
  next_step: string;
  notes: string;
  notify_via_email: string;
  override_cpt: string;
  override_cpt_by: string;
  protocol_type: string;
  qles: string;
  rescheduled_at: string;
  rescheduled_info: string;
  rescheduled_review_notes: string;
  rescheduled_signed_email: string;
  rescheduled_to: string;
  risk_assessment_json: string;
  session_length: string;
  session_type: SessionTypes | SessionTypesV2 | SessionTypesV3;
  start_time_in_iso8601_utc: string;
  start_ts: string;
  state: string;
  successfully_completed_and_billable: string;
  summary_appropriate_for_nocd: string;
  provisional_referral_needed: string;
  summary_cpt: string;
  summary_end_time: string;
  summary_icd_json: string[];
  summary_not_continuing_nocd_plan: string;
  summary_not_continuing_nocd_text: string;
  summary_present_json: string;
  summary_present_other: string;
  summary_private_notes: string;
  summary_start_time: string;
  summary_treatment_goals: string[];
  summary_treatment_progress: string;
  summary_treatment_response: string;
  summary_types_of_treatment_erp: number;
  summary_types_of_treatment_other: string;
  user_id: number;
  user_email: string;
  zoom_id: string;
  zoom_join_link: string;
  zoom_password: string;
  dynamic_forms: {
    appointment_id: number;
    completed_at: string;
    created_at: string;
    deleted_by: string;
    deleted_linked_appointment_id: number;
    form_fields_data: { [key: string]: number | string };
    form_id: number;
    form_scores_data: { [key: string]: number | string };
    id: number;
    is_completed: number;
    is_deleted: number;
    name: string;
    updated_at: string;
  }[];
  supervisor_review_status?: SupervisorAppointmentNoteReviewStatus | null;
  can_restore_cancelled: number;
  can_restore_completed: number;
  review_requested_at: string;
  final_appointment_confirmation?: number | null;
  send_cancel_follow_up?: number | null;
  primary_icd_code?: string;
  secondary_icd_codes?: string[] | null;
}

// Appointment note types

export interface AppointmentNoteBgDiagnosticsForm {
  bg_verbal_consent_given: string;
  bg_verbal_consent_given_by: string;
  bg_brain_trauma: number;
  bg_consent_reviewed: number;
  bg_linked_account_roi_reviewed?: number;
  bg_coping_skills: string;
  bg_current_relationship_status: string;
  bg_current_relationship_status_text: string;
  bg_demographic: string;
  bg_family_history_json: {
    family_history: {
      ocd: string[];
      anxiety: string[];
      depression_mood: string[];
      substance_abuse: string[];
      schizophrenia_psychosis: string[];
    };
  };
  bg_legal_problems: string;
  bg_legal_problems_text: string;
  bg_medical_history: string;
  bg_medical_history_text: string;
  bg_medical_past_er_visits_text: string;
  bg_medication_status: string;
  bg_medication_status_text: string;
  bg_misdiagnosed_conditions_json: {
    conditions: string[];
  };
  bg_misdiagnosed_conditions_other: string;
  bg_misdiagnosed_ocd: string;
  bg_ocd_criteria_time: string;
  bg_ocd_criteria_units: string;
  bg_outpatient_treatment: string;
  bg_outpatient_treatment_text: string;
  bg_patient_goals: string;
  bg_presenting_problem: string;
  bg_prior_non_ocd_diagnosis: string;
  bg_prior_non_ocd_diagnosis_text: string;
  bg_prior_ocd_by_prof: string;
  bg_trauma_denied: number;
  bg_trauma_items: {
    trauma_items: string[];
  };
  bg_trauma_other: string;
  bg_verified_identity: number;
  created_at: string;
  id: number;
  member_appointments_id: number;
  ocd_tab_diamond_all_screenings_negative: number;
  ocd_tab_diamond_details: string;
  ocd_tab_dsm_distress: number;
  ocd_tab_dsm_distress_domains: string[];
  ocd_tab_dsm_functional_impairment: number;
  ocd_tab_dsm_insight: string;
  ocd_tab_dsm_part_a: number;
  ocd_tab_dsm_part_b: number;
  ocd_tab_dsm_part_c: number;
  ocd_tab_dsm_part_d: number;
  ocd_tab_dsm_tic_related: string;
  ocd_tab_subtype: string;
  ocd_tab_subtype_contamination: string;
  ocd_tab_subtype_contamination_text: string;
  ocd_tab_subtype_harm: string;
  ocd_tab_subtype_harm_text: string;
  ocd_tab_subtype_other: string;
  ocd_tab_subtype_other_text: string;
  ocd_tab_subtype_religious: string;
  ocd_tab_subtype_religious_text: string;
  ocd_tab_subtype_sexual: string;
  ocd_tab_subtype_sexual_text: string;
  ocd_tab_subtype_somatic: string;
  ocd_tab_subtype_somatic_text: string;
  ocd_tab_subtype_symmetry: string;
  ocd_tab_subtype_symmetry_text: string;
  updated_at: string;
  user_id: number;
  ocd_assessment_details: string;
  bg_life_goals_post_treatment: string;
}

export interface AppointmentNoteDiamondAdultForm {
  completed: number;
  created_at: string;
  id: number;
  member_appointments_id: number;
  protocol_type?: string;
  q1_adult_frequent_thoughts: number;
  q2_adult_repetitive_behaviors: number;
  q3_adult_worry_appearance: number;
  q4_adult_excessively_cluttered: number;
  q5_adult_hair_pulling: number;
  q6_adult_skin_picking: number;
  q7_adult_socially_anxious: number;
  q8_adult_panic_attack: number;
  q9_adult_fearful_of_escape: number;
  q10_adult_worry_about_many_things: number;
  q11_adult_afraid_of_objects_situations_activities: number;
  q12_adult_afraid_to_be_away_from_a_person: number;
  q13_adult_problematically_happy: number;
  q14_adult_depressed_2_years: number;
  q15_adult_unmotivated_2_weeks: number;
  q16_adult_women_only_really_depressed_during_period: number;
  q17_adult_event_trauma: number;
  q18_adult_hard_time_with_stressful_experience: number;
  q19_adult_strange_beliefs: number;
  q20_adult_sensory_experience: number;
  q21_adult_avoid_eating_due_to_weight: number;
  q22_adult_eating_binges: number;
  q23_adult_eat_very_little: number;
  q24_adult_physical_health: number;
  q25_adult_worry_of_serious_medical_illness: number;
  q26_adult_alcohol: number;
  q27_adult_drug_abuse: number;
  q28_adult_difficulty_paying_attn: number;
  q29_adult_difficult_sitting_still: number;
  q30_adult_spastic_movements: number;
  q1_adult_frequent_thoughts_positive: number;
  q2_adult_repetitive_behaviors_positive: number;
  q3_adult_worry_appearance_positive: number;
  q4_adult_excessively_cluttered_positive: number;
  q5_adult_hair_pulling_positive: number;
  q6_adult_skin_picking_positive: number;
  q7_adult_socially_anxious_positive: number;
  q8_adult_panic_attack_positive: number;
  q9_adult_fearful_of_escape_positive: number;
  q10_adult_worry_about_many_things_positive: number;
  q11_adult_afraid_of_objects_situations_activities_positive: number;
  q12_adult_afraid_to_be_away_from_a_person_positive: number;
  q13_adult_problematically_happy_positive: number;
  q14_adult_depressed_2_years_positive: number;
  q15_adult_unmotivated_2_weeks_positive: number;
  q16_adult_women_only_really_depressed_during_period_positive: number;
  q17_adult_event_trauma_positive: number;
  q18_adult_hard_time_with_stressful_experience_positive: number;
  q19_adult_strange_beliefs_positive: number;
  q20_adult_sensory_experience_positive: number;
  q21_adult_avoid_eating_due_to_weight_positive: number;
  q22_adult_eating_binges_positive: number;
  q23_adult_eat_very_little_positive: number;
  q24_adult_physical_health_positive: number;
  q25_adult_worry_of_serious_medical_illness_positive: number;
  q26_adult_alcohol_positive: number;
  q27_adult_drug_abuse_positive: number;
  q28_adult_difficulty_paying_attn_positive: number;
  q29_adult_difficult_sitting_still_positive: number;
  q30_adult_spastic_movements_positive: number;
  q1_adult_frequent_thoughts_details: string;
  q2_adult_repetitive_behaviors_details: string;
  q3_adult_worry_appearance_details: string;
  q4_adult_excessively_cluttered_details: string;
  q5_adult_hair_pulling_details: string;
  q6_adult_skin_picking_details: string;
  q7_adult_socially_anxious_details: string;
  q8_adult_panic_attack_details: string;
  q9_adult_fearful_of_escape_details: string;
  q10_adult_worry_about_many_things_details: string;
  q11_adult_afraid_of_objects_situations_activities_details: string;
  q12_adult_afraid_to_be_away_from_a_person_details: string;
  q13_adult_problematically_happy_details: string;
  q14_adult_depressed_2_years_details: string;
  q15_adult_unmotivated_2_weeks_details: string;
  q16_adult_women_only_really_depressed_during_period_details: string;
  q17_adult_event_trauma_details: string;
  q18_adult_hard_time_with_stressful_experience_details: string;
  q19_adult_strange_beliefs_details: string;
  q20_adult_sensory_experience_details: string;
  q21_adult_avoid_eating_due_to_weight_details: string;
  q22_adult_eating_binges_details: string;
  q23_adult_eat_very_little_details: string;
  q24_adult_physical_health_details: string;
  q25_adult_worry_of_serious_medical_illness_details: string;
  q26_adult_alcohol_details: string;
  q27_adult_drug_abuse_details: string;
  q28_adult_difficulty_paying_attn_details: string;
  q29_adult_difficult_sitting_still_details: string;
  q30_adult_spastic_movements_details: string;
  session_type: SessionTypes | SessionTypesV2 | SessionTypesV3;
  updated_at: string;
  user_id: number;
  adult_proxy_balance_of_will_assess?: string;
}

export interface AppointmentNoteDiamondYouthForm {
  completed: number;
  created_at: string;
  id: number;
  member_appointments_id: number;
  protocol_type: ProtocolTypes;
  q10_youth_fearful_of_escape: number;
  q10_youth_fearful_of_escape_positive: number;
  q11_youth_worry_about_many_things: number;
  q11_youth_worry_about_many_things_positive: number;
  q12_youth_afraid_of_objects_situations_activities: number;
  q12_youth_afraid_of_objects_situations_activities_positive: number;
  q13_youth_afraid_to_be_away_from_a_person: number;
  q13_youth_afraid_to_be_away_from_a_person_positive: number;
  q14_youth_problematically_happy: number;
  q14_youth_problematically_happy_positive: number;
  q15_youth_depressed_2_years: number;
  q15_youth_depressed_2_years_positive: number;
  q16_youth_unmotivated_2_weeks: number;
  q16_youth_unmotivated_2_weeks_positive: number;
  q17_youth_temper: number;
  q17_youth_temper_positive: number;
  q18_youth_women_only_really_depressed_during_period: number;
  q18_youth_women_only_really_depressed_during_period_positive: number;
  q19_youth_event_trauma: number;
  q19_youth_event_trauma_positive: number;
  q1_youth_frequent_thoughts: number;
  q1_youth_frequent_thoughts_positive: number;
  q20_youth_hard_time_with_stressful_experience: number;
  q20_youth_hard_time_with_stressful_experience_positive: number;
  q21_youth_strange_beliefs: number;
  q21_youth_strange_beliefs_positive: number;
  q22_youth_sensory_experience: number;
  q22_youth_sensory_experience_positive: number;
  q23_youth_avoid_eating_due_to_weight: number;
  q23_youth_avoid_eating_due_to_weight_positive: number;
  q24_youth_eating_binges: number;
  q24_youth_eating_binges_positive: number;
  q25_youth_eat_very_little: number;
  q25_youth_eat_very_little_positive: number;
  q26_youth_physical_health: number;
  q26_youth_physical_health_positive: number;
  q27_youth_worry_of_serious_medical_illness: number;
  q27_youth_worry_of_serious_medical_illness_positive: number;
  q28_youth_alcohol: number;
  q28_youth_alcohol_positive: number;
  q29_youth_drug_abuse: number;
  q29_youth_drug_abuse_positive: number;
  q2_youth_repetitive_behaviors: number;
  q2_youth_repetitive_behaviors_positive: number;
  q30_youth_difficulty_paying_attn: number;
  q30_youth_difficulty_paying_attn_positive: number;
  q31_youth_difficult_sitting_still: number;
  q31_youth_difficult_sitting_still_positive: number;
  q32_youth_spastic_movements: number;
  q32_youth_spastic_movements_positive: number;
  q33_youth_hurt_people: number;
  q33_youth_hurt_people_positive: number;
  q34_youth_feel_angry_or_cranky: number;
  q34_youth_feel_angry_or_cranky_positive: number;
  q35_youth_broken_law: number;
  q35_youth_broken_law_positive: number;
  q3_youth_worry_appearance: number;
  q3_youth_worry_appearance_positive: number;
  q4_youth_excessively_cluttered: number;
  q4_youth_excessively_cluttered_positive: number;
  q5_youth_hair_pulling: number;
  q5_youth_hair_pulling_positive: number;
  q6_youth_skin_picking: number;
  q6_youth_skin_picking_positive: number;
  q7_youth_refuse_to_speak: number;
  q7_youth_refuse_to_speak_positive: number;
  q8_youth_embarassed_around_others: number;
  q8_youth_embarassed_around_others_positive: number;
  q9_youth_uncomfortable_feelings: number;
  q9_youth_uncomfortable_feelings_positive: number;
  q1_youth_frequent_thoughts_details: string;
  q2_youth_repetitive_behaviors_details: string;
  q3_youth_worry_appearance_details: string;
  q4_youth_excessively_cluttered_details: string;
  q5_youth_hair_pulling_details: string;
  q6_youth_skin_picking_details: string;
  q7_youth_refuse_to_speak_details: string;
  q8_youth_embarassed_around_others_details: string;
  q9_youth_uncomfortable_feelings_details: string;
  q10_youth_fearful_of_escape_details: string;
  q11_youth_worry_about_many_things_details: string;
  q12_youth_afraid_of_objects_situations_activities_details: string;
  q13_youth_afraid_to_be_away_from_a_person_details: string;
  q14_youth_problematically_happy_details: string;
  q15_youth_depressed_2_years_details: string;
  q16_youth_unmotivated_2_weeks_details: string;
  q17_youth_temper_details: string;
  q18_youth_women_only_really_depressed_during_period_details: string;
  q19_youth_event_trauma_details: string;
  q20_youth_hard_time_with_stressful_experience_details: string;
  q21_youth_strange_beliefs_details: string;
  q22_youth_sensory_experience_details: string;
  q23_youth_avoid_eating_due_to_weight_details: string;
  q24_youth_eating_binges_details: string;
  q25_youth_eat_very_little_details: string;
  q26_youth_physical_health_details: string;
  q27_youth_worry_of_serious_medical_illness_details: string;
  q28_youth_alcohol_details: string;
  q29_youth_drug_abuse_details: string;
  q30_youth_difficulty_paying_attn_details: string;
  q31_youth_difficult_sitting_still_details: string;
  q32_youth_spastic_movements_details: string;
  q33_youth_hurt_people_details: string;
  q34_youth_feel_angry_or_cranky_details: string;
  q35_youth_broken_law_details: string;
  session_type: AllSessionTypes;
  updated_at: string;
  user_id: number;
  youth_proxy_balance_of_will_assess?: string;
}

export interface AppointmentNoteAddendum {
  id: number;
  created_at: string;
  is_deleted: number;
  addendum: string;
}

export interface AppointmentNoteRiskAssessment {
  death_details: string;
  death_details_text: string;
  death_intention: string;
  death_intention_text: string;
  death_plan: string;
  death_plan_text: string;
  death_preparation: string;
  death_preparation_text: string;
  death_thoughts: string;
  death_thoughts_text: string;
  deathwish: string;
  deathwish_text: string;
  homicidality: string[];
  risk_details: string;
  risk_details_history?: string;
  safety_env: string;
  safety_plan: string;
  safety_step1: string;
  safety_step2: string;
  safety_step3: string;
  safety_step4: string;
  safety_step5: string;
  self_injury: string[];
  suicidality: string[];
}

export interface AppointmentNote {
  admd_visit_id: number;
  amd_appt_backfill: string;
  addendums: AppointmentNoteAddendum[];
  appointment_date: string;
  appointment_state: string;
  appointment_type: string;
  autoinvoice_id: number;
  bg_address_setter: string;
  bg_diagnostics_form: AppointmentNoteBgDiagnosticsForm;
  bg_diagnostics_id: number;
  bg_followup_changes_to_doctor_visits: string;
  bg_followup_changes_to_doctor_visits_text: string;
  bg_followup_changes_to_medication: string;
  bg_followup_changes_to_medication_text: string;
  bg_reported_current_address: string;
  bg_reported_current_state: string;
  billing_complete: number;
  billing_state: string;
  booked_author: string;
  cancellation_author: string;
  cancellation_info: string;
  cancellation_ts: string;
  cancelled_via_reschedule: number;
  claim_reference_id: string;
  claim_status: string;
  clinical_forms_dass21_id: number;
  clinical_forms_diamond_adult_id: number;
  clinical_forms_diamond_youth_id: number;
  clinical_forms_docs_id: number;
  clinical_forms_qles_id: number;
  clinician_compensated: string;
  clinician_email: string;
  clinician_signed_email: string;
  clinician_timezone: string;
  completion_at: string;
  completion_info: string;
  created_at: string;
  dass_anxiety: number;
  dass_depression: number;
  dass_stress: number;
  diagnosed_with_ocd: number;
  diamond_adult_form: AppointmentNoteDiamondAdultForm;
  diamond_distress_score: number;
  diamond_functional_score: number;
  diamond_severity_score: number;
  diamond_youth_form: AppointmentNoteDiamondYouthForm;
  discharge: number;
  docs_severity: number;
  docs_contamination_score: number;
  docs_responsibility_score: number;
  docs_unacceptable_thoughts_score: number;
  docs_symmetry_score: number;
  end_time_in_iso8601_utc: string;
  end_ts: string;
  event_id: string;
  // first_session_has_completed_diamond_severity is only needed for the second session to determine if we should allow the clinician to fill out the diamond severity questions
  // if the first session has completed diamond severity questions, then signal to the client not to render the diamond severity questions in the second session
  first_session_has_completed_diamond_severity: number;
  gcal_id: string;
  has_forms: number;
  has_scores: string;
  id: number;
  medical_additional_comments: string;
  medical_json: {
    medical_json: {
      speech: string[];
      cognition: string[];
      mood_affect: string[];
      danger_to_self: string[];
      thought_content: string[];
      thought_process: string[];
      danger_to_others: string[];
      insight_judgement: string[];
      appearance_behavior: string[];
    };
  };
  medical_within_normal_limits: string | number;
  member: Member;
  member_takeaway_notes: string;
  needs_audit: number;
  needs_billing_review: number;
  needs_billing_review_notes: string;
  needs_clinical_review: number;
  needs_clinical_review_notes: string;
  needs_review_notes: string;
  next_step: string;
  notes: string;
  override_cpt: string;
  override_cpt_by: string;
  protocol_type: string;
  rescheduled_at: string;
  rescheduled_info: string;
  rescheduled_review_notes: string;
  rescheduled_signed_email: string;
  rescheduled_to: number;
  risk_assessment_json: {
    risk_assessment: AppointmentNoteRiskAssessment;
  };
  session_length: string;
  session_type: AllSessionTypes;
  sign_note_session_length: string;
  start_time_in_iso8601_utc: string;
  start_ts: string;
  successfully_completed_and_billable: number;
  summary_appropriate_for_nocd: string;
  provisional_referral_needed: string;
  summary_cpt: string;
  summary_end_time: string;
  summary_icd_json: string[];
  summary_icd_specifiers_text: string;
  summary_not_continuing_nocd_plan: string;
  summary_not_continuing_nocd_text: string;
  summary_present_json: string[];
  summary_present_other: string;
  summary_private_notes: string;
  summary_start_time: string;
  summary_treatment_goals: string[];
  summary_treatment_progress: number;
  summary_treatment_response: string;
  summary_types_of_treatment_erp: number;
  summary_types_of_treatment_other: string;
  user_id: number;
  zoom_id: string;
  zoom_join_link: string;
  zoom_password: string;
  zoom_meeting_link: string;
  note_version: string;
  canadian_masters_level: number;
  canadian_diagnostic_info: string;
  canadian_province: string;
  forms: {
    id: number;
    completed_at: string;
    form_fields_data: { [key: string]: number | string };
    form_name: string;
    form_scores_data: { [key: string]: number | string };
    is_completed: number;
    is_deleted: number;
    user_id: number;
  }[];
  therapy_case_type: string;
  previous_session_therapy_case_type: string;
  needs_supervisor_review: number;
  requested_supervisor: string;
}

export interface SupervisorReviewNote {
  id?: number;
  user_id: number;
  note_text: string;
  clinician_email: string;
  member_appointments_id: number;
  created_at?: string;
  is_deleted?: number;
}
